@font-face {
  font-family: 'Montserrat-Light';
  src: url('../fonts/Montserrat-Light.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat-SemiBold.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CeraPro-Bold';
  src: url('../fonts/CeraPro-Bold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'CeraPro-Light';
  src: url('../fonts/CeraPro-Light.woff2');
  font-display: swap;
}
