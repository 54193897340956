@import '@/assets/scss/variables.scss';
@import '@/assets/scss/fonts.scss';

@mixin respond-to($media) {
  @media screen and (max-width: $media) {
    @content;
  }
}

@mixin defaultStyleButton(
  $top: 16px,
  $right: 40px,
  $bottom: 16px,
  $left: 40px,
  $radius: 50px
) {
  background-color: $default-color-button;
  color: $default-text-white-color;
  padding-top: $top;
  padding-right: $right;
  padding-bottom: $bottom;
  padding-left: $left;
  border-radius: $radius;
  width: fit-content;
  text-decoration: none;
  border: none;
  font-family: 'Montserrat-Light', sans-serif;
  cursor: pointer;
}

@mixin videoPlayer($top: 50%, $width: 1266px, $height: 1066px) {
  position: absolute;
  top: $top;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $width;
  height: $height;
}

@mixin lightText($size: 14px, $LineHeight: 20px) {
  font-size: $size;
  font-family: 'Montserrat-Light', sans-serif;
  line-height: $LineHeight;
  font-weight: 500;
}

@mixin mediumText($size: 18px, $LineHeight: 25px) {
  font-size: $size;
  font-family: 'Montserrat-Medium', sans-serif;
  line-height: $LineHeight;
  font-weight: 600;
}

@mixin regularText($size: 18px, $LineHeight: 25px) {
  font-size: $size;
  font-family: 'Montserrat-Regular', sans-serif;
  line-height: $LineHeight;
  font-weight: 400;
}

@mixin semiboldText($size: 18px, $LineHeight: 25px) {
  font-size: $size;
  font-family: 'Montserrat-SemiBold', sans-serif;
  line-height: $LineHeight;
  font-weight: 600;
}

@mixin boldText($size: 18px, $LineHeight: 25px) {
  font-size: $size;
  font-family: 'Montserrat-Bold', sans-serif;
  line-height: $LineHeight;
  font-weight: 700;
}

@mixin info($padding: 25px 10px 25px 14px) {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $default-background-color;
  padding: $padding;
  border-radius: 30px;
  max-height: 64px;
  height: 100%;
}
