@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixin.scss';
@import '@/assets/scss/fonts.scss';

.button {
  @include defaultStyleButton(24px, 32px, 24px, 32px);
  max-width: fit-content;
  margin: 0 auto;
  text-align: center;

  @include respond-to(980px) {
    width: 100%;
    max-width: 512px;
  }

  @include respond-to(768px) {
    @include defaultStyleButton(16px, 32px, 16px, 32px);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  margin: 0 auto;
  padding-left: 10px;
  padding-right: 10px;
}

.footer {
  background-color: #1f1f1f;
  min-height: 480px;
  width: 100%;
  max-height: 480px;
  color: $default-text-white-color;

  @include respond-to(512px) {
    min-height: 326px;
    padding-bottom: 30px;
    max-height: 515px;
  }

  @include respond-to(320px) {
    min-height: 450px;
  }
}

.footer_top {
  width: 100%;
  margin: 0 auto;
  flex-grow: 2;
  max-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 45px;

  @include respond-to(980px) {
    margin-bottom: 50px;
    max-height: 250px;
  }

  @include respond-to(720px) {
    max-height: 350px;
    margin-top: 20px;
  }

  & p {
    @include regularText(26px);
    margin-bottom: 31px;

    @include respond-to(768px) {
      @include regularText(20px);
    }

    @include respond-to(512px) {
      @include regularText(14px);
    }

    @include respond-to(320px) {
      text-align: center;
    }
  }
}

.footer_bottom {
  display: flex;
  justify-content: space-between;
  @include boldText(16px, 17px);
  width: 95%;

  @include respond-to(980px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    & :nth-child(1) {
      margin-bottom: 15px;
    }
  }

  @include respond-to(720px) {
    justify-content: space-between;
    max-height: 80px;
  }
}

.footer_bottom__copyRight {
  font-family: 'CeraPro-Light';
  font-size: 12px;
  color: #cfcfcf;
  text-align: center;
}

.footer_bottom__email {
  font-family: 'CeraPro-Light';
  font-size: 12px;
  text-align: center;
  color: #cfcfcf;
  a {
    color: #cfcfcf;
    font-weight: normal;
  }
}

.footer_bottom_made {
  font-size: 12px;
  text-align: center;
  font-family: 'CeraPro-Light';
  color: #cfcfcf;

  & a {
    font-family: 'CeraPro-Bold';
    color: #fff;
    text-decoration: none;
  }
}

.footer_list_rules {
  width: 100%;
  max-width: 840px;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @include respond-to(720px) {
    max-width: 150px;
    margin-bottom: 25px;
  }

  &__item {
    margin-bottom: 10px;
    font-size: 12px;
    font-family: 'Montserrat-Light', sans-serif;
    font-weight: normal;
    color: #cfcfcf;
    text-align: center;
    a {
      color: #cfcfcf;
      text-decoration: none;
      font-weight: normal;
      cursor: pointer;
    }
  }
}
